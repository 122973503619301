import jwt_decode from "jwt-decode";
import moment from "moment";

export const segmentCall = (
  profile: any,
  token: string,
  segmentType: string = "",
  referral: string = ""
) => {
  const decoded: any = jwt_decode(token);
  let segmentPayload: any = {
    "Account Status": profile.status,
    Age: moment(profile.dateOfBirth).fromNow().substring(0, 2),
    "Area Code": profile.postalCode.substring(0, 2),
    City: "Singapore",
    Country: "Singapore",
    DOB: profile.dateOfBirth,
    "Driver License Class": profile.drivingLicences[0].licenceClass,
    "Driver License Expiry": profile.drivingLicences[0].licenceExpiryDate,
    "Driver License Issued Date": profile.drivingLicences[0].licenceIssueDate,
    Email: profile.email,
    Gender: profile.gender === "male" || profile.gender === "m" ? "M" : "F",
    Name: profile.fullName,
    Phone: profile.phoneNumber,
    "Postal Code": profile.postalCode,
    Registered: true,
    userId: decoded.UserId,
    "Referral Code": profile.referralCode,
  };
  if (segmentType === "firstReg") {
    segmentPayload["Account Creation Date"] = moment().utc().format();
  }
  if (segmentType === "myInfo" && profile.status === "Active") {
    segmentPayload["Account Creation Date"] = moment().utc().format();
    segmentPayload["Account Verified Date"] = moment().utc().format();
  }
  if (referral) {
    segmentPayload["Referral User"] = Boolean(referral);
  }
  window.analytics.identify(decoded.UserId, segmentPayload);
};

export const segmentCTapCalls = (event: string, segmentPayload: any) => {
  return window.analytics.track(event, segmentPayload);
};
