import { Loadable } from "components/Loadable/Loadable";

const routes = [
  {
    path: "/",
    name: "Landing",
    location: "views/Landing/Landing",
    element: <Loadable path={"views/Landing/Landing"} />,
    authenticated: true,
  },
  {
    path: "sign-up",
    name: "Signup",
    exact: true,
    fixed: true,
    location: "views/SignUp/SignUp",
    element: <Loadable path={"views/SignUp/SignUp"} />,
  },
  {
    path: "sign-up-otp",
    name: "SignUpOTP",
    exact: true,
    fixed: true,
    location: "views/SignUp/SignUpOTP/SignUpOTP",
    element: <Loadable path={"views/SignUp/SignUpOTP/SignUpOTP"} />,
  },
  {
    path: "sign-up-selection",
    name: "SignUpSelection",
    exact: true,
    location: "views/SignUp/SignUpSelection/SignUpSelection",
    element: <Loadable path="views/SignUp/SignUpSelection/SignUpSelection" />,
  },
  {
    path: "manual-registration",
    name: "ManualRegistration",
    exact: true,
    location: "views/SignUp/ManualRegistration/ManualRegistration",
    element: (
      <Loadable path="views/SignUp/ManualRegistration/ManualRegistration" />
    ),
  },
  {
    path: "review-information",
    name: "MyInfo",
    exact: true,
    location: "views/SignUp/MyInfo/MyInfo",
    element: <Loadable path={"views/SignUp/MyInfo/MyInfo"} />,
  },
  {
    path: "myinfo",
    name: "MyInfo",
    exact: true,
    location: "views/SignUp/MyInfo/MyInfo",
    element: <Loadable path={"views/SignUp/MyInfo/MyInfoWindow"} />,
  },
  {
    path: "consent",
    name: "MyInfoConsent",
    exact: true,
    location: "views/SignUp/MyInfo/MyInfoConsent",
    element: <Loadable path={"views/SignUp/MyInfo/MyInfoConsent"} />,
  },
  {
    path: "account/my-profile",
    name: "MyProfile",
    exact: true,
    location: "views/SignUp/MyInfo/MyInfoConsent",
    element: <Loadable path={"views/Account/MyProfile/MyProfile"} />,
    showMenu: true,
    authenticated: true,
  },
  {
    path: "locations",
    name: "Locations",
    exact: true,
    location: "views/Booking/Locations/Locations",
    element: <Loadable path={"views/Booking/Locations/Locations"} />,
    showMenu: true,
    authenticated: true,
  },
  {
    path: "car-details",
    name: "CarDetails",
    exact: true,
    fixed: true,
    location: "views/Booking/CarDetails/CarDetails",
    element: <Loadable path={"views/Booking/CarDetails/CarDetails"} />,
    showMenu: true,
    authenticated: true,
    params: ":carId",
  },
  {
    path: "confirmed",
    name: "BookingConfirmed",
    exact: true,
    fixed: true,
    location: "views/Booking/BookingConfirmed/BookingConfirmed",
    element: (
      <Loadable path={"views/Booking/BookingConfirmed/BookingConfirmed"} />
    ),
    showMenu: true,
    authenticated: true,
  },
  {
    path: "booking-confirmation",
    name: "BookingConfirmation",
    exact: true,
    fixed: true,
    location: "views/Booking/BookingConfirmation/BookingConfirmation",
    element: (
      <Loadable
        path={"views/Booking/BookingConfirmation/BookingConfirmation"}
      />
    ),
    showMenu: true,
    authenticated: true,
    params: ":carId",
  },
];

export default routes;
