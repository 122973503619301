import * as React from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./Layout.scss";

const Layout: React.FC = ({ children }) => {
  const { authenticated } = useSelector((state: RootStateOrAny) => state.auth);
  const navigate = useNavigate();
  // React.useEffect(() => {
  //   if (authenticated) {
  //     navigate("/");
  //   }
  // }, [authenticated]);
  return <div className="app-body">{children}</div>;
};

export default Layout;
