import { api } from "services/helpers";

export const requestOTP = async (phoneNumber: string) => {
  try {
    const res = await api("/Authentication/GetNewVerifyPin", "POST", {
      phoneNumber,
      countryCode: "65",
    });
    if (res.data.error_code !== 0) {
      return { redirect: false, message: "Invalid number, give it another go" };
    }
    return { redirect: true, phoneNumber };
  } catch (err) {
    return { redirect: false, message: "Invalid number, give it another go" };
  }
};

export const verifyOTP = (phoneNumber: string | any, pinCode: string) =>
  api("/Authentication/VerifyPin", "POST", {
    countryCode: "65",
    deviceType: "ios",
    phoneNumber,
    pinCode,
  });
