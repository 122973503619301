import React from "react";
import { Modal, ModalBody } from "reactstrap";
import { AiOutlineClose } from "react-icons/ai";

interface Props {
  show?: boolean;
  title?: string;
  onDialogClose?: () => void;
  centered?: boolean;
  style?: any;
  size?: string;
  withIcon?: boolean;
}
const CModal: React.FC<Props> = function ({
  show = false,
  children,
  title,
  onDialogClose = () => null,
  withIcon = true,
  ...props
}) {
  return (
    <Modal isOpen={show} toggle={onDialogClose} {...props}>
      <span
        style={{
          position: "absolute",
          zIndex: 100,
          right: 30,
          top: 10,
          fontSize: "22px",
          cursor: "pointer",
        }}
        onClick={onDialogClose}
      >
        {withIcon && <AiOutlineClose />}
      </span>
      <ModalBody>{children}</ModalBody>
    </Modal>
  );
};

export default CModal;
