import React, { useEffect } from "react";
import { Provider, RootStateOrAny, useSelector } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
// import { configureToolkit } from "./store";
import { QueryClientProvider, QueryClient } from "react-query";
import "./App.scss";
import MainRouter from "./containers/Nav/MainRouter";
import axios from "axios";
import { configureToolkit } from "./store";

declare global {
  interface Window {
    analytics: any;
  }
}

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
export const store = configureToolkit();
export const persistor = persistStore(store);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <MainRouter />
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
