import React, { useEffect, useRef, useState } from "react";

export const useWidth = () => {
  const [width, setWidth] = useState<number>(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const mobileWidth = 768;
  const isMobile = width < mobileWidth;
  return isMobile;
};

export const useActive = (time: number) => {
  const [active, setActive] = useState(false);
  const timer = useRef<undefined | number>();
  const events = ["keypress", "mousemove", "touchmove", "click", "scroll"];

  useEffect(() => {
    setActive(true);
    const handleEvent = () => {
      setActive(true);

      if (timer.current) {
        window.clearTimeout(timer.current);
      }

      timer.current = window.setTimeout(() => {
        setActive(false);
      }, time);
    };

    events.forEach((event) => document.addEventListener(event, handleEvent));

    return () => {
      events.forEach((event) => {
        document.removeEventListener(event, handleEvent);
      });
    };
  }, [time]);

  return active;
};
