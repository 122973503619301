import { requestOTP, verifyOTP } from "services/auth/auth.service";
import { segmentCall, segmentCTapCalls } from "utils/segment";

import {
  action,
  createAction,
  createAsyncAction,
  createCustomAction,
} from "typesafe-actions";

export const authOTPVerifyAsyncAction: any = createAsyncAction(
  "AUTH_OTP_REQUEST",
  "AUTH_OTP_SUCCESS",
  "AUTH_OTP_FAILURE"
)<{ phoneNumber: string }, any, Error>();

export const authLoginAsyncAction: any = createAsyncAction(
  "AUTH_LOGIN_REQUEST",
  "AUTH_LOGIN_SUCCESS",
  "AUTH_LOGIN_FAILURE"
)<{ payload: any }, any, Error>();

export const authEditProfileAsyncAction: any = createAsyncAction(
  "AUTH_EDIT_REQUEST",
  "AUTH_EDIT_SUCCESS",
  "AUTH_EDIT_FAILURE"
)<{ payload: any }, any, Error>();

export const authLogoutAction: any = createAsyncAction(
  "AUTH_LOGOUT_REQUEST",
  "AUTH_LOGOUT_SUCCESS",
  "AUTH_LOGOUT_FAILURE"
)<any, any, Error>();

// export const dispatchOTPRequest = (phoneNumber: string) => {
//   return async (dispatch: any) => {
//     try {
//       const res = await requestOTP(phoneNumber);
//       // if (res.data.error_code !== 0) {
//       //   dispatch(authOTPRequestAsyncAction.failure(res.data.error_msg));
//       // } else {
//       //   dispatch(authOTPRequestAsyncAction.success(res.data.data));
//       // }
//     } catch (err) {
//       // dispatch(authOTPRequestAsyncAction.failure(err));
//     }
//   };
// };

export const storeMobile = (mobile: string) => {
  return async (dispatch: any) => {
    dispatch(action("STORE_MOBILE", { mobile }));
  };
};

export const setAppModal = () => {
  return async (dispatch: any) => {
    dispatch(action("SET_APP_MODAL"));
  };
};
export const setVerifModal = () => {
  return async (dispatch: any) => {
    dispatch(action("SET_VERIF_MODAL"));
  };
};
export const setAuthModal = () => {
  return async (dispatch: any) => {
    dispatch(action("SET_AUTH_MODAL"));
  };
};

export const dispatchVerifyOTP = (payload: any) => {
  const cleverTapLoginSuccess = () =>
    segmentCTapCalls("Login", {
      LoginMode: "SMS OTP", // string values:"Biometric", "SMS OTP"
      Status: "Success", // string "Success", "Failed"
    });

  const cleverTapLoginFail = () =>
    segmentCTapCalls("Login", {
      LoginMode: "SMS OTP", // string values:"Biometric", "SMS OTP"
      Status: "Failure", // string "Success", "Failed"
    });

  return async (dispatch: any) => {
    try {
      dispatch(authOTPVerifyAsyncAction.request());
      const res = await verifyOTP(payload.phoneNumber, payload.pinCode);
      if (res.data.error_code === 999) {
        cleverTapLoginFail();
        dispatch(authOTPVerifyAsyncAction.success(res.data.data));
      } else if (res.data.error_code === 0) {
        cleverTapLoginSuccess();
        dispatch(authLoginAsyncAction.success(res.data.data));
      } else {
        cleverTapLoginFail();
        dispatch(authOTPVerifyAsyncAction.failure(res.data.error_msg));
        return { message: "Invalid code, give it another go." };
      }
    } catch (err) {
      cleverTapLoginFail();
      dispatch(authOTPVerifyAsyncAction.failure(err));
      return { message: "Invalid code, give it another go." };
    }
  };
};

export const dispatchLogin = (
  payload: any,
  segmentType?: string,
  referral?: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(authLoginAsyncAction.request());
      if (payload.status === 200) {
        dispatch(
          authLoginAsyncAction.success({
            ...payload.data.data,
            segmentType,
            referral,
          })
        );
      } else {
        dispatch(authLoginAsyncAction.failure(payload.data.error.msg));
      }
    } catch (err) {
      dispatch(authLoginAsyncAction.failure(err));
    }
  };
};
export const dispatchEditProfile = (payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(authEditProfileAsyncAction.request());
      if (payload.status === 200) {
        dispatch(authEditProfileAsyncAction.success(payload.data.data));
      } else {
        dispatch(authEditProfileAsyncAction.failure(payload.data.error.msg));
      }
    } catch (err) {
      dispatch(authEditProfileAsyncAction.failure(err));
    }
  };
};

export const dispatchLogout = () => (dispatch: any) => {
  dispatch(authLogoutAction.request());
  localStorage.removeItem("persist:auth");
};

export const refreshTokenAction = () => {
  return async (dispatch: any) => {
    try {
    } catch (err) {}
  };
};
