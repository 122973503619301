import * as React from "react";
import { Button } from "reactstrap";

const AuthModal = () => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center py-4">
      <div>
        <h3 className="cH3 cH3--pink">Please log in again</h3>
      </div>
      <div className="mb-4">
        <span>
          Your session has expired or you've logged in on a different device.
        </span>
      </div>
      <div className="w-100 px-5">
        <Button
          className="themeButton themeButton--primary"
          block
          onClick={() =>
            window.location.replace(window.location.origin + "/sign-up")
          }
        >
          Okay
        </Button>
      </div>
    </div>
  );
};

export default AuthModal;
