import { dispatchLogout, setAuthModal } from "actions/auth/authAction";
import { store } from "App";
import axios from "axios";
import { isEmpty } from "lodash";
import { stringify } from "qs";

const API_URL = process.env.REACT_APP_API_URL;
const needBasicAuth = process.env.REACT_APP_BASIC_AUTH;

export const api = (
  path: string,
  method = "GET",
  payload: any,
  params?: any,
  version?: any,
  token = "",
  header = "json",
  responseType = ""
) => {
  const options: any = {
    url: API_URL + path,
    method: method.toUpperCase(),
    headers: {},
  };

  const instance = axios.create(options);

  if (!isEmpty(params)) {
    options["url"] =
      options["url"] + stringify(params, { addQueryPrefix: true });
  }

  if (token) {
    options["headers"]["Authorization"] = "Bearer " + token;
  }

  if (!token && needBasicAuth) {
    options["auth"] = {
      username: process.env.REACT_APP_AUTH_USER_NAME,
      password: process.env.REACT_APP_AUTH_PASSWORD,
    };
  }

  //this will work with all kind of payload
  if (!isEmpty(payload) || !!payload?.entries()) {
    options["data"] = payload;
  }

  if (responseType) {
    options["responseType"] = responseType;
  }

  switch (header) {
    // if multipart ignore the option headers
    case "multipart":
      options["headers"]["Content-Type"] = "multipart/form-data";
      break;
    case "csv":
      options["headers"]["Accept"] = "application/csv";
      options["responseType"] = "blob";
      break;
    default:
      options["headers"]["Content-Type"] = "application/json";
  }

  instance.interceptors.response.use(
    async (response) => {
      if (response.data.Code === 401) {
        const state = store.getState();
        const refreshToken = state.auth.refreshToken;
        const res = await instance.post(
          "/Authentication/RefreshToken",
          {},
          {
            headers: {
              Authorization: `Bearer ${refreshToken}`,
            },
          }
        );
        if (res.data.error_code === 400) {
          store.dispatch(dispatchLogout());
          store.dispatch(setAuthModal());
          return;
        }
        options["headers"]["Authorization"] =
          "Bearer " + res.data.data.refreshToken;
        return instance(options);
      } else {
        return response;
      }
    },
    async (error: any) => {
      if (error.status === 401 || !error.response) {
        const state = store.getState();
        const refreshToken = state.auth.refreshToken;
        const res = await instance.post(
          "/Authentication/RefreshToken",
          {},
          {
            headers: {
              Authorization: `Bearer ${refreshToken}`,
            },
          }
        );
        if (res.data.error_code === 400) {
          store.dispatch(dispatchLogout());
          store.dispatch(setAuthModal());
          return;
        }
        options["headers"]["Authorization"] =
          "Bearer " + res.data.data.refreshToken;
        return instance(options);
      }
    }
  );
  return instance(options);
};

export function apiResolver(response: any, cb = (response: any) => null) {
  if (!response) {
    return Promise.reject("error");
  }
  if (response?.error_code !== 0) {
    // TODO: show only something went wrong exception if the environment is for the production;
    return Promise.reject(response.error_msg);
  } else {
    return cb(response);
  }
}

/**
 * Extract actual data from the api
 * @param data
 */
export const apiSelector = (data: any) =>
  apiResolver(data, (response: any) => response);
