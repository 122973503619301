import { createReducer } from "typesafe-actions";
import AuthReducerDto from "dtos/authReducer.dto";
import { REHYDRATE, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
  authEditProfileAsyncAction,
  authLoginAsyncAction,
  authLogoutAction,
  authOTPVerifyAsyncAction,
} from "actions/auth/authAction";

const initialState: AuthReducerDto = {
  authenticated: false,
  token: "",
  profile: {},
  mobileInput: "",
  refreshToken: "",
  appModal: false,
  verifModal: false,
  authModal: false,
  login: {
    loading: false,
    error: "",
    code: 200,
  },
};

const authReducer: any = persistReducer(
  {
    key: "auth",
    storage,
    whitelist: [
      "token",
      "profile",
      "mobileInput",
      "authenticated",
      "verifModal",
      "refreshToken",
    ],
  },

  createReducer(initialState)
    .handleAction(REHYDRATE, (state: any, action: any): any => {
      if (action.key !== "auth") return state;
      return {
        ...state,
        ...action.payload,
      };
    })
    .handleAction(authLoginAsyncAction.request, (state: any): any => ({
      ...state,
      login: { ...initialState.login, loading: true },
    }))
    .handleAction(
      authLoginAsyncAction.success,
      (state: any, { payload }: any): any => {
        return {
          ...state,
          login: { ...initialState.login, loading: true, code: 0 },
          profile: payload.profile,
          token: payload.accessToken,
          refreshToken: payload.refreshToken,
          authenticated: true,
          verifModal: payload.profile.status === "Pending",
          segmentType: payload.segmentType,
          referral: payload.referral,
        };
      }
    )
    .handleAction(authOTPVerifyAsyncAction.request, (state: any): any => {
      return {
        ...state,
        login: { ...initialState.login, loading: true },
      };
    })
    .handleAction(
      authOTPVerifyAsyncAction.success,
      (state: any, { payload }: any) => ({
        ...state,
        token: payload.accessToken,
        refreshToken: payload.refreshToken,
        login: {
          loading: false,
          error: "",
          code: 999,
        },
      })
    )
    .handleAction(
      authOTPVerifyAsyncAction.failure,
      (state: any, { payload }: any) => ({
        ...state,
        login: {
          loading: false,
          error: payload,
        },
      })
    )
    .handleAction(authLogoutAction.request, (state: any) => ({
      ...state,
      ...initialState,
    }))
    .handleAction(authEditProfileAsyncAction.request, (state: any) => ({
      ...state,
    }))
    .handleAction(
      authEditProfileAsyncAction.success,
      (state: any, action: any) => ({
        ...state,
        profile: {
          ...state.profile,
          ...action.payload,
        },
      })
    )
    .handleAction("STORE_MOBILE", (state: any, action: any): any => {
      return {
        ...state,
        mobileInput: action.payload.mobile,
      };
    })
    .handleAction("SET_APP_MODAL", (state: any, action: any): any => {
      return {
        ...state,
        appModal: !state.appModal,
      };
    })
    .handleAction("SET_VERIF_MODAL", (state: any, action: any): any => {
      return {
        ...state,
        verifModal: false,
      };
    })
    .handleAction("SET_AUTH_MODAL", (state: any, action: any): any => {
      return {
        ...state,
        authModal: !state.authModal,
      };
    })

  /*
      [
        authLogoutAction,
        (state: any) => ({
          ...state,
          token: "",
          profile: {},
          authenticated: false,
        }),
      ],
    ]),
    initialState
  )*/
);
export default authReducer;
