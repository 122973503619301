import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import routes from "config/route";
import { useWidth } from "utils/hooks";
import Layout from "./Layout";
import { RootStateOrAny, useSelector } from "react-redux";
import CModal from "components/CModal/CModal";
import AuthModal from "components/AuthModal/AuthModal";

const Header = React.lazy(() => import("./Layout/Header/Header"));

export const MainRoutes = () => {
  const isMobile = useWidth();
  const { authModal } = useSelector((state: RootStateOrAny) => state.auth);
  return (
    // <Suspense fallback={<Loader />}>
    <Layout>
      <Routes>
        <Route path="/" element={<Header />} />
        {!isMobile && (
          <>
            <Route
              path="/sign-up"
              element={<Header type="light" fixed={true} />}
            />
            <Route
              path="/sign-up-otp"
              element={<Header type="light" fixed={true} />}
            />
            <Route
              path="/sign-up-selection"
              element={<Header type="light" />}
            />
            <Route
              path="/manual-registration"
              element={<Header type="light" />}
            />
            <Route
              path="/review-information"
              element={<Header type="light" />}
            />
            <Route
              path="/locations"
              element={<Header type="light" showMenu mapSearch />}
            />
            <Route
              path="/car-details/:carId"
              element={<Header type="light" showMenu={true} />}
            />
            <Route
              path="/car-details"
              element={<Header type="light" showMenu={true} />}
            />
            <Route
              path="/confirmed"
              element={<Header type="light" showMenu />}
            />
            <Route
              path="/booking-confirmation/:carId"
              element={<Header type="light" showMenu={true} />}
            />
          </>
        )}
        <Route
          path="/account/my-profile"
          element={<Header type="light" showMenu={true} />}
        />
      </Routes>
      <Routes>
        {routes.map(({ path, element, params }, idx) => {
          if (params) {
            return (
              <Route path={path} element={element} key={idx}>
                <Route path=":carId" element={element} />
              </Route>
            );
          }
          return <Route path={path} element={element} key={idx} />;
        })}
      </Routes>
      <CModal
        show={authModal}
        centered
        onDialogClose={() =>
          window.location.replace(window.location.origin + "/sign-up")
        }
        withIcon={false}
      >
        <AuthModal />
      </CModal>
    </Layout>
    // </Suspense>
  );
};
