import React, { lazy } from "react";

export interface LoadableProps {
  path: string;
}

export const Loadable: React.FC<LoadableProps> = ({ path }) => {
  const MyComponent = lazy(() => {
    const myModule = import(`../../${path}`);
    return myModule;
  });

  return <MyComponent />;
};
