import {
  locationAction,
  currentLocationAction,
  rentalDateAction,
  mapLocationAction,
  searchedLocationAction,
  loadingAction,
} from "actions/maps/mapAction";
import { createReducer } from "typesafe-actions";

export const initialState = {
  currentLocation: {
    lat: 0,
    lng: 0,
  },
  mapLocation: {
    lat: 0,
    lng: 0,
  },
  searchedLocation: {
    lat: 0,
    lng: 0,
  },
  rental_start_date: "",
  rental_end_date: "",
  dataList: [],
  loading: true,
};

const mapReducer = createReducer(initialState)
  .handleAction(
    currentLocationAction.success,
    (state: any, { payload }: any): any => {
      return {
        ...state,
        currentLocation: {
          lat: payload.lat,
          lng: payload.lng,
        },
      };
    }
  )
  .handleAction(
    mapLocationAction.success,
    (state: any, { payload }: any): any => {
      return {
        ...state,
        mapLocation: {
          lat: payload.lat,
          lng: payload.lng,
        },
      };
    }
  )
  .handleAction(
    searchedLocationAction.success,
    (state: any, { payload }: any): any => {
      return {
        ...state,
        searchedLocation: {
          lat: payload.lat,
          lng: payload.lng,
        },
      };
    }
  )
  .handleAction(
    rentalDateAction.success,
    (state: any, { payload }: any): any => {
      return {
        ...state,
        rental_start_date: payload.start,
        rental_end_date: payload.end,
      };
    }
  )
  .handleAction(loadingAction.success, (state: any, { payload }: any): any => {
    return {
      ...state,
      loading: payload,
    };
  })
  .handleAction(locationAction.success, (state: any, { payload }: any): any => {
    return {
      ...state,
      dataList: payload,
    };
  });
export default mapReducer;
