import React, { Suspense, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import Loader from "components/Loader/Loader";
import { MainRoutes } from "containers/Routes";
import { RootStateOrAny, useSelector } from "react-redux";
import { segmentCall } from "utils/segment";

const MainRouter: React.FC = () => {
  const { authenticated, profile, token, segmentType } = useSelector(
    (state: RootStateOrAny) => state.auth
  );

  useEffect(() => {
    if (authenticated && !segmentType) {
      segmentCall(profile, token);
    }
  }, [authenticated]);
  return (
    <BrowserRouter>
      <Suspense fallback={Loader}>
        <MainRoutes />
      </Suspense>
    </BrowserRouter>
  );
};

export default MainRouter;
