import { createAsyncAction, action } from "typesafe-actions";

export const currentLocationAction: any = createAsyncAction(
  "CURRENT_EDIT_REQUEST",
  "CURRENT_EDIT_SUCCESS",
  "CURRENT_EDIT_FAILURE"
)<{ payload: any }, any, Error>();
export const mapLocationAction: any = createAsyncAction(
  "MAP_EDIT_REQUEST",
  "MAP_EDIT_SUCCESS",
  "MAP_EDIT_FAILURE"
)<{ payload: any }, any, Error>();
export const searchedLocationAction: any = createAsyncAction(
  "SEARCH_EDIT_REQUEST",
  "SEARCH_EDIT_SUCCESS",
  "SEARCH_EDIT_FAILURE"
)<{ payload: any }, any, Error>();

export const rentalDateAction: any = createAsyncAction(
  "DATE_EDIT_REQUEST",
  "DATE_EDIT_SUCCESS",
  "DATE_EDIT_FAILURE"
)<{ payload: any }, any, Error>();
export const locationAction: any = createAsyncAction(
  "LOCATION_EDIT_REQUEST",
  "LOCATION_EDIT_SUCCESS",
  "LOCATION_EDIT_FAILURE"
)<{ payload: any }, any, Error>();

export const loadingAction: any = createAsyncAction(
  "LOADING_EDIT_REQUEST",
  "LOADING_EDIT_SUCCESS",
  "LOADING_EDIT_FAILURE"
)<{ payload: any }, any, Error>();

export const changeCurrentLocation = ({ lat, lng }: any) => {
  return async (dispatch: any) => {
    dispatch(currentLocationAction.success({ lat, lng }));
  };
};
export const changeMapLocation = ({ lat, lng }: any) => {
  return async (dispatch: any) => {
    dispatch(mapLocationAction.success({ lat, lng }));
  };
};
export const changeSearchLocation = ({ lat, lng }: any) => {
  return async (dispatch: any) => {
    dispatch(searchedLocationAction.success({ lat, lng }));
  };
};

export const changeRentalDate = ({ start, end }: any) => {
  return async (dispatch: any) => {
    dispatch(rentalDateAction.success({ start, end }));
  };
};

export const setLocationsData = (data: Array<any>) => {
  return async (dispatch: any) => {
    if (data && data.length) {
      dispatch(locationAction.success(data));
    } else {
      dispatch(locationAction.success([]));
    }
  };
};

export const setMapLoading = (bool: Boolean) => {
  return async (dispatch: any) => {
    dispatch(loadingAction.success(bool));
  };
};
